(function($) { $(document).ready(function() {
 
/*--------------------------------------------------------------
# Global
--------------------------------------------------------------*/

var rellax = new Rellax('.rellax');

/* 
  Global Variables
-------------------------------*/
var $html = $('html'),
	$body = $('body'),
	$doc = $(document);
/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/

//touch detection

window.addEventListener('touchstart', function() {
    console.log('touch');
  $('body').addClass('touch'); 
});


/*
  Level Bulding:

  adds useful classes to the 
  navigation structure
-------------------------------*/
$('#mainnav>div:not(.mobile-menu-top):not(.mobile-menu-bottom) ul').each(function(){

	//caching
	var $this = $(this);

	//Active Trail
	var activeTrail  = $this.find('.sfSel').parent();
	activeTrail.addClass('active-trail');

	$this.find('li:last-child').addClass('last');

	//Add class has children
	var hasParent = $this.parent('li');
	if(hasParent.length){
		hasParent.addClass('haschildren');
		hasParent.append('<span class="droptrigger"></span>');
	}
	
	//Detect level and add class
	var depth = $this.parents('ul').length + 1;
	$this.addClass('navlevel-' + depth);
    
   


});

function thirdLevel(){
    if($(window).width()<768){
        $('.navlevel-2 .haschildren > a').each(function(){
            var thelink=$(this).attr('href');
            $(this).next('ul').prepend('<li><a href="'+thelink+'">Overview</a></li>');
        });
    }
}

//Set 3rd level toggle
if($(window).width()<768){
    $('.navlevel-2 .haschildren > a').on('click',function(e){
        e.preventDefault();
        $(this).parent('li').toggleClass('open');
    });
}


thirdLevel();


/*
  Triggers
-------------------------------*/

//Menu
var triggerMenu = $('a.tb-menu');
triggerMenu.on('click', function(event){
	event.preventDefault();
	$body.addClass('activate-nav');
});

//Trigering Events "drawers"
$('.droptrigger').on('click', function(){
	var list = $(this).parent();
	list.siblings('.active').removeClass('active').find('>ul').slideUp();
	list.toggleClass('active');
	list.find('>ul').slideToggle('200', 'swing');
});

//Untrigger
var untrigger = $('div.overlaytrigger, #mobilenav-close');
untrigger.on('click', function(event) {
	event.preventDefault();
	$body.removeClass('activate-nav');
	$('#mainnav .active').removeClass('active').find('>ul').attr('style', '');
});

//Search
var triggerSearch = $('a.tb-search');
triggerSearch.on('click', function(event){
	event.preventDefault();
	$body.toggleClass('activate-search');
});

//Close multiple using the document
$doc.click(function(event){
	if( !$(event.target).closest('#search-header').length && !$(event.target).is(triggerSearch) ){
		$body.removeClass('activate-search');
	}
});

$('.sfSearchBoxSet .searchTxtBox').attr('placeholder', 'Search');

var pageOverviewTitle;

if( $('#mainnav .top-nav > ul > li > a.sfSel').length ) {
    pageOverviewTitle = $('#mainnav .top-nav .sfSel').first().text();
    $( '<div class="page-overview-title"><div class="section-inner">' + pageOverviewTitle + '</div></div>').appendTo('#masthead');
}else if( $('.sfNoBreadcrumbNavigation > a:contains("earch")').length ) {
    pageOverviewTitle = $('.sfNoBreadcrumbNavigation > a').text();
    $( '<div class="page-overview-title"><div class="section-inner">' + pageOverviewTitle + '</div></div>').appendTo('#masthead');
}

$('.footer-nav ul').each(function(){

    //caching
    var $this = $(this);


    //Add class has children
    var hasParent = $this.parent('li');

    if( hasParent.length ){
        hasParent.addClass('haschildren');
    }
    
});

var $footerLink = $('.footer-nav .sfNavList > li > a');

$footerLink.on('click', function(){
    $(this).parent().toggleClass('active-now');
    return false;
});


/*--------------------------------------------------------------
# Sliders 
--------------------------------------------------------------*/
if ($('.slides').length) {
    var autoPlay = $('.autoPlay').val() == "true";
    var autoPlaySpeed = $('.autoPlaySpeed').val();
    var showThumbnail = $('.showThumbnail').val() == "true";
    var showDot = $('.showDot').val() == "true";
    var showArrow = $('.showArrow').val() == "true";
    var fade = $('.isFade').val() == "true";

    var $sliders = $('.masthead-image .flexslider:not(.flexslider--coa) .slides');
    $sliders.slick({
        dots: showDot,
        autoplay: autoPlay,
        autoplaySpeed: autoPlaySpeed,
        arrows: showArrow,
        fade: fade
    });

    if (showThumbnail) {
        var $slidersNav = $('.slides-nav');
        $slidersNav.slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.slides',
            centerMode: true,
            focusOnSelect: true,
            arrows: !showArrow
        });

    }

    if ($html.hasClass('ie8')) {
        setTimeout(function () {
            $(window).trigger('resize');
            $('body').addClass('loaded');
        }, 1000);
    }
}

if( $('.flexslider--coa .slides').length && $(window).width()>768 ){
    $('.flexslider--coa .slides').slick({
        autoplay: true,
        dots: true,
        arrows: false,
        fade: true,
        speed: 1000,
        autoplaySpeed:6000,
        appendDots: '.ca-bottom-cta__inner',
        lazyLoad: 'ondemand',
        // responsive: [
        //     {
        //       breakpoint: 640,
        //       settings: "unslick"
        //     }
        // ]
    });
}
/*--------------------------------------------------------------
# Lists
--------------------------------------------------------------*/
var $overviewBlock = $('li.overviewBlock').first();
if($overviewBlock.length){
	$overviewBlock.parent().addClass('overview-blocks-wrapper');
}

/*--------------------------------------------------------------
# Tabs
--------------------------------------------------------------*/


/*
  Tabs generator
-------------------------------*/
var tabs = $('.tabs');
var url = window.location.href;
if (tabs.length) {
    if (url.indexOf('Action/Edit') == -1) {
        tabs.each(function () {

            var $this = $(this);

            //wrap the content divs into their own section
            $this.wrapInner('<ul class="tabs-accordion cf"></ul>');

            //defining variables
            var tabsItem = $this.find('>.tabs-accordion>div'),
            tabsNav = [],
            tabFirst = true;
            var mytab;

            //for each tabs item
            tabsItem.each(function (i) {

                //define the variables
                var $this = $(this),
                    tabTitle = $this.data('placeholder-label'),
                    tabID = tabTitle.replace(/[^a-zA-Z0-9]/g, '_'); //tabTitle.replace('&', '').toLowerCase().replace(/\s+/g, '').split('_');

                //add Identifier to parent div
                if (tabFirst) {
                    mytab = buildtabNavItem($this, tabID, tabTitle, true);
                    tabFirst = false;
                } else {
                    mytab = buildtabNavItem($this, tabID, tabTitle, false);
                }

            });

            //inform our tabs have been generated
            $this.addClass('ready');
        });
    }
}

//Function for adding attributes and generating nav item
function buildtabNavItem(tab, tabID, tabTitle, firstItem){

    var tabClass;
    var panelClass;

    if( firstItem ){
        tabClass = 'tab is-selected';
        panelClass = 'panel';
    }else{
        tabClass = 'tab';
        panelClass = 'panel is-hidden';
    }

    tab.wrap('<li><div id="panel-'+tabID+'" class="'+panelClass+'" role="tabpanel" aria-labelledby="tab-'+tabID+'" aria-hidden="'+firstItem+'"></div></li>');

    $('<a id="tab-'+tabID+'" href="javascript:void(0)" class="'+tabClass+'" aria-controls="panel-'+tabID+'" aria-selected="'+firstItem+'" role="tab"><span>'+tabTitle+'</span></a>').prependTo(tab.parent().parent());
    
}


/*
  Tabs functionality
-------------------------------*/
function triggerTab($el) {

    //defining variables
    var $this = $el,
        $selected = $this.closest('.tabs').find('.tab.is-selected');
    tabId = $this.attr('aria-controls');
    $panel = $this.closest('.tabs').find('.panel');
    tabpan = $('#' + tabId);

    //deselect all the tabs and panels
    $selected.removeClass('is-selected').attr('aria-selected', 'false');
    $panel.addClass('is-hidden').attr('aria-hidden', 'true');

    // select this tab and panel
    $this.addClass('is-selected').attr('aria-selected', 'true');
    tabpan.removeClass('is-hidden').attr('aria-hidden', 'false');

}
if (url.indexOf('Action/Edit') == -1) {// not in edit mode

    var tabLink = $('a[role="tab"]');

    //regular click
    tabLink.on('click', function () {

        triggerTab($(this));

    });

    //on enter
    tabLink.keydown(function (ev) {
        if (ev.which == 13) {
            $(this).click();
        }
    });



    /*--------------------------------------------------------------
    # Sticky elements
    --------------------------------------------------------------*/

    /*
      Header
      Uncomment to enable
    -------------------------------*/

    $('.h-w1')
        .stick_in_parent({
            parent: $('#wrapper'),
            offset_top: -100,
            bottoming: false
        })
        .on("sticky_kit:stick", function () {
            $(this).removeClass('fadeInUp').addClass('slideInDown');
        })
        .on("sticky_kit:unstick", function () {
            $(this).removeClass('slideInDown').addClass('fadeIn');
        });

    // $('.subnav-left')
    //     .stick_in_parent({
    //         parent: $('.colmain'),
    //         offset_top: 100
    //     });



    /*--------------------------------------------------------------
    # Is element visible on viewport
    --------------------------------------------------------------*/

    var elementActive = function(el){
        var $el = $(el);
        if( $el.visible(true) ){
            $el.addClass('active');
        }else {
            $el.removeClass('active');
        }
        return $el;
    };

    $(window).scroll(function() {
        clearTimeout(window.resizedFinished);
        window.resizedFinished = setTimeout(function(){
            elementActive('.f-w2');
        }, 20);
    });

    /*--------------------------------------------------------------
    # Video Pop Up using magnific popup from sitefinity
    --------------------------------------------------------------*/

    $(".playLink").magnificPopup({ type: 'iframe' });

    $('.photogallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            //titleSrc: function(item) {
            //  return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
            //}
        }
    });
} else {
    //alert("edit mo");
}

/*--------------------------------------------------------------
# Colour coding
--------------------------------------------------------------*/
$('#util a, .footer-bottom-nav a').each(function(){
    var $this = $(this);
    var textClass = 'class-' + $this.text().replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();
    $this.addClass(textClass);
});


if( $('body[class*="--home"]').length ) {
    var $theMaplink = $('.locations-sittings + .sfContentBlock a');
    $theMaplink.hover(
        function(){
            $(this).closest('.sf_colsIn').addClass('on');
        },
        function(){
            $(this).closest('.sf_colsIn').removeClass('on');
        }
    );
}


/*--------------------------------------------------------------
# Scrolling
--------------------------------------------------------------*/

$(function() {
  $('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 600);
        return false;
      }
    }
  });
});


$('.overview-blocks').parent().parent().addClass('ov-blocks');

$('table').wrap('<div class="responsive-tables"></div>');


if( $('.single-banner-responsive').length ) {
    //$('.single-banner-responsive').attr('data-rellax-speed', '-4');
    //var bannerRellax = new Rellax('.single-banner-responsive');
}


if( $('.location-map, .location-map-detail-area').length ) {
    $('body').addClass('page-template-location-map');
}

if( $('.table-files.documents-library').length ) {
    $('.table-files.documents-library').each(function(){
        var $this = $(this);
        var title = $this.parent().parent().find('.sfitemFieldLbl').addClass('hidden').text();
         if(title != ''){
            $this.prepend('<caption>'+ title +'</caption');
        }
    });
}


if( $('.rgMasterTable').length ) {
    $('.rgMasterTable').each(function(){
        var $this = $(this);
        var title = $this.closest('.sfdownloadTable').prev('.sfmediaFieldTitle').addClass('hidden').text();
        if(title != ''){
            $this.prepend('<caption>'+ title +'</caption');
        }
        

        $this.find('.sfdownloadType').each(function(){
            var $this = $(this);
            var $span = $('<span class="icon-' + $this.text().toLowerCase() + '"></span>');
            
            $this.html('');
            $span.appendTo($this);
        });

    });

}


/*------------------------------------
Adding placeholders to form fields
--------------------------------------*/

$('.input .sfExample').each(function(){
    var thePlaceholder=$(this).text();
    console.log(thePlaceholder);
    $(this).previous('input').attr('placeholder',thePlaceholder);
});

/*--------------------------------------------------------------
# homepage Court of Appeal
--------------------------------------------------------------*/
if( $('.ac-latest-news').length ) {
    var words = $('.ac-latest-news .date-section .month-day');

    $('.ac-latest-news .date-section .month-day').each(function(){
        var $this = $(this);
        var $text = $this.text().trim();
        var words = $text.split(/(\s+)/);
        $this.empty();
        $('<div>'+words[2]+'</div>' + '<div>'+words[0]+'</div>').appendTo($this);
    }); 
}

/*--------------------------------------------------------------
# Go to errors
--------------------------------------------------------------*/

if($('.sfError').length){
    $('html,body').animate({
      scrollTop: $('.sfError').offset().top - 200
    }, 1000);
} else if($('.sfErrorSummary span').length){
    $('html,body').animate({
      scrollTop: $('.sfFormCaptcha').offset().top - 200
    }, 1000);
} else if($('.sfSuccess').text() !=''){
    $('html,body').animate({
      scrollTop: $('.sfSuccess').offset().top - 200
    }, 1000);
}




/*--------------------------------------------------------------
# homepage panning
--------------------------------------------------------------*/
// if( $('.page-template-homesplash').length ) {
//     var $wrapper = $('#wrapper');

//     $wrapper.on('mousemove', function(e){
//         $(this).find('.overlaytrigger').css(
//             {'transform-origin': ((e.pageX - $(this).offset().left) / $(this).width()) * 100 + '% ' + ((e.pageY - $(this).offset().top) / $(this).height()) * 100 +'%'
//         });
//     });
// }

}); })(jQuery);